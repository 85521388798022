<style>
    .custom-primary-button {
        background: #5854F1;
        background-image: linear-gradient(54deg, #5854F1, #3535C4, #5854F1, #3535C4);
        text-align:center;
        background-size: 300% 100%;
        moz-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
    }

    .custom-primary-button:hover {
        opacity: 0.75;
    }

    .custom-primary-button:focus {
        outline: none;
        background: #3535C4;
    }

    .custom-buttom-disabled {
        background: #e5e7eb !important;
        color: #5f5f5f !important;
        pointer-events: none;
    }
</style>

<template>
    <button :disabled="disabled" :class="btnClass + (iconPosition == 'left' ? ' flex-wrap-reverse ' : '') + (disabled ? ' custom-buttom-disabled' : '')" class="w-fill custom-primary-button rounded-lg py-2 px-5 mt-auto text-white cursor-pointer flex justify-center text-center">
        <div>{{label}}</div>
        <div>
            <FontAwesomeIcon class="ml-4 self-center animate-spin" v-if="loading" :icon="spinner"/>
        </div>
    </button>
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

    export default {
        name: 'PrimaryButton',
        props: ['label', 'icon', 'loading', 'iconPosition', 'disabled', 'btnClass'],
        components: {
            FontAwesomeIcon
        },
        data () {
            return {
                spinner: faCircleNotch
            }
        },
    }
</script>