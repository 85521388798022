<template>
  <Card title="Parabéns! Conta cadastrada com sucesso.">
    <p class="text-lg font-medium mt-4">
      Sua conta bancária está pronta para ser utilizada. <br />
      Clique no botão "Finalizar" para iniciar sua nova jornada.
    </p>
    <div class="flex justify-end gap-3 mt-8">
      <Button @click="back" :icon="backIcon" iconPosition="left" label="Voltar" class="hover:bg-gray-300" />
      <PrimaryButton @click="next" :icon="nextIcon" iconPosition="right" :class="{ 'custom-buttom-disabled': loading }" :loading="loading" label="Finalizar" />
    </div>
  </Card>
</template>

<script>
import PrimaryButton from "../../../buttons/PrimaryButton";
import Button from "../../../buttons/Button";
import Card from "../../../ui/Card";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export default {
  name: "PlugPixData",
  props: ["data", "clientType", "loading"],
  components: {
    Card,
    PrimaryButton,
    Button,
  },
  data() {
    return {
      nextIcon: faChevronRight,
      backIcon: faChevronLeft,
      clientID: null,
      clientSecret: null,
      formValidation: {},
    };
  },
  async created() { },
  methods: {
    changeField(index, value) {
      if (index == "clientID") {
        this.clientID = value;
      }
      if (index == "clientSecret") {
        this.clientSecret = value;
      }

      this.validate(index);
    },

    async validate(index) {
      switch (index) {
        case "clientID":
          if (!this.clientID) {
            this.formValidation[index] = "Insira um identificador válido.";
            return false;
          }
          break;
        case "clientSecret":
          if (!this.clientSecret) {
            this.formValidation[index] = "Insira a senha.";
            return false;
          }
          break;
      }
      this.formValidation[index] = null;
    },

    next() {
      this.$emit("next")
    },

    back() {
      this.$emit("back", this.data);
    },
  },
};
</script>
