<style>
#auth {
  top: -100%;
  transition: 1s;
  z-index: 0;
}

.auth-active {
  top: 0% !important;
}

#form {
  top: 100%;
  transition: 1s;
  width: inherit;
  z-index: 1;
}

.form-active {
  top: 0% !important;
}
</style>

<template>
  <div v-if="loadingData" :class="loadingData ? 'absolute flex h-screen w-screen bg-gray-50' : 'invisible'">
    <div class="m-auto z-10 w-screen">
      <embed width="150" height="150" src="/colorful-loading.gif" class="mx-auto mb-6" />
    </div>
  </div>
  <div v-else class="w-full h-full absolute">
    <AuthSoftwareHouse id="auth" class="absolute h-full" :class="authenticating ? 'auth-active' : ''"
      clientType="software-houses" v-if="!registering || authenticating" @resume="resume" @new="newRegister" />
    <div id="form" class="absolute" :class="!authenticating ? 'form-active' : ''">
      <CreateSoftwareHouse 
        :CNPJ="CNPJ" 
        clientType="software-houses" 
        class="bg-white" 
        :estabelecimentoCnpj="CNPJ"
        :cnpjLocked="cnpjLocked" 
        :estabelecimentoRazaoSocial="softwareHouseRazaoSocial"
        :preRegister="preRegister"
        v-if="!authenticating || registering" 
        :data="formData" 
        :protocolValue="Protocol" 
        @cancel="back" />
    </div>
  </div>
</template>

<script>
import CreateSoftwareHouse from '../business/Create'
import AuthSoftwareHouse from '../business/Auth'
import api from "@/services/api";

export default {
  name: 'SoftwareHouse',
  components: {
    CreateSoftwareHouse,
    AuthSoftwareHouse
  },
  data() {
    return {
      registering: false,
      authenticating: true,
      formData: {},
      Protocol: null,
      loadingData: true,
      CNPJ: null,
      softwareHouseRazaoSocial: null,
      preRegister: null,
    }
  },
  mounted() {
    this.getData(this.$route.params.hash)

    setTimeout(() => { this.loadingData = false }, 3500)
  },
  methods: {
    async getData(uuid) {
      api.get(`/pre-register/software-house/${uuid}`)
      .then((response) => {
        this.preRegister = response.data
        this.CNPJ = this.preRegister.cnpj
        
        api.get("/software-houses/disponibilidade/" + this.CNPJ)
        .then((response) => {
          if (!response.data.disponivel) {
            this.$swal({
              title: "Erro!",
              text:
                "O CNPJ vinculado a esse cadastro já está cadastrado e tem um contrato ativo com o tecnopay.\n" +
                "Por favor, entre em contato com nossa equipe para maiores esclarecimentos.",
              confirmButtonText: "Ok",
            }).then(() => {
              this.$router.push("/");
            });
          } 
          else {
            this.cnpjLocked = true;
            this.softwareHouseRazaoSocial = this.preRegister.corporateName
            if(!this.preRegister.isRegistered) this.authenticating = false
          }
        })
      })
    },

    async newRegister(tecnoaccount) {
      this.authenticating = false
      this.formData = {
        Pais: 'Brasil',
        empresa: {
          CNPJ: this.CNPJ
        }
      }

      if (tecnoaccount) {
        this.formData = {
          empresa: {
            CNPJ: tecnoaccount.cnpj,
            Pais: 'Brasil',
            Isento: true
          },
          tecnoaccount: tecnoaccount
        }
      }
      await setTimeout(() => this.registering = true, 1000)
    },

    async back() {
      this.authenticating = true
      this.formData = {}
      this.Protocol = null
      window.scroll({
        top: 0,
        left: 0,
      });
      await setTimeout(() => this.registering = false, 1000)
    },

    async resume(data) {
      this.authenticating = false
      this.formData = data.data
      this.Protocol = data.protocol
      await setTimeout(() => this.registering = true, 1000)
    }
  }
}
</script>