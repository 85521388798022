<style>
html {
  scroll-behavior: smooth;
}

body {
  font-family: 'VisbyRoundCF-Regular' !important;
}

@font-face {
  font-family: "VisbyRoundCF-Regular";
  src: url('/font/VisbyRoundCF-Regular.otf') format("opentype");
  font-style: normal;
}
</style>

<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
}
</script>