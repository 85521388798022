<template>
    <div class="root">
        <div class="container">
            <ul class="progressbar">
                <li :class="{ active: index < progress, error: errorProgress }" v-for="(item, index) in steps" :key="index" class="text-base">{{ item }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: ['progress', 'errorProgress'],
    data() {
        return {
            steps: ['Assinar Documentos', 'Solicitar Cadastro', 'Verificar Situação', 'Finalizar Cadastro']
        }
    },
}
</script>

<style scoped>
.container {
    width: 100%;
    position: absolute;
    z-index: 1;
    margin-top: 20px;
}

.progressbar {
    counter-reset: step;
}

.progressbar li {
    float: left;
    width: 15.28%;
    position: relative;
    text-align: center;
}

.progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 30px;
    height: 30px;
    border: 2px solid #bebebe;
    display: block;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    line-height: 27px;
    background: white;
    color: #bebebe;
    text-align: center;
    font-weight: bold;
}

.progressbar li:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background: #979797;
    top: 15px;
    left: -50%;
    z-index: -1;
}

.progressbar li.active:before {
    border-color: #22c55e;
    background: #22c55e;
    color: white
}

.progressbar li.active:after {
    background: #22c55e;
}

.progressbar li.active li:after {
    background: #22c55e;
}

.progressbar li.active li:before {
    border-color: #22c55e;
    background: #22c55e;
    color: white
}

/* error */

.progressbar li.active.error:before {
    border-color: #ef4444;
    background: #ef4444;
    color: white
}

.progressbar li.active.error:after {
    background: #ef4444;
}

.progressbar li.active.error li:after {
    background: #ef4444;
}

.progressbar li.active.error li:before {
    border-color: #ef4444;
    background: #ef4444;
    color: white
}

.progressbar li:first-child:after {
    content: none;
}
</style>