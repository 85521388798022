<template>
  <div class="grid">
    <h3 class="font-bold">{{ title }}</h3>

    <input :id="`file-${fileName.replace(' ', '-')}`" :name="fileName" type="file" class="hidden"
      @change="handleUploadToStorage">

    <input :id="title" placeholder="Selecione um arquivo pdf ou imagem" type="text"
      :class="validation ? 'border-red-500 border-2 ' + fileName : fileName"
      class="mt-2 border border-dashed w-full rounded-lg border-gray-400 px-3 py-2 text-gray-900 outline-none transition-all duration-200 cursor-pointer bg-gray-100 hover:bg-gray-300 hover:border-transparent"
      v-model="inputValue" @click="openExplorer">

    <span class="text-xs">Extensões aceitas: .png, .jpeg, .pdf (Limite de 1MB)</span>
  </div>

</template>

<script>
import api from "@/services/api";

export default {
  name: 'FileUpload',
  props: ["title", "fileName", "validation", "estabelecimentoCnpj", "value"],
  emits: ['changeField', 'update:value'],
  data() {
    return {
      inputValue: this.value || null,
      check: false,
      fileHash: ""
    };
  },
  watch: {
    value(newValue) {
      this.inputValue = newValue;
    }
  },
  methods: {
    async handleUploadToStorage(event) {
      const filename = event.target.name

      if (event.target.files && event.target.files[0]) {
        this.file = event.target.files[0];
        this.inputValue = this.file.name

        if (this.file.type !== "image/png" && this.file.type !== "image/jpeg" && this.file.type !== "application/pdf") {
          this.$swal({
            title: "Erro!",
            icon: "error",
            text: "O arquivo deve ser do formato PDF, PNG ou JPEG.",
            confirmButtonText: "Ok",
          });

          event.target.value = null;
          this.inputValue = null;
          return;
        }

        if (this.file.size > 1024 * 1024) {
          this.$swal({
            title: "Erro!",
            icon: "error",
            text: "O arquivo deve ter no máximo 1MB.",
            confirmButtonText: "Ok",
          });

          event.target.value = null;
          this.inputValue = null;
          return;
        }

        let base64Data = null

        const reader = new FileReader();
        reader.onload = async () => {
          base64Data = reader.result;

          await api
            .put('/matera/documents',
              {
                companyCnpj: this.estabelecimentoCnpj,
                document: base64Data,
                documentType: filename
              }, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }).then((response) => {
              localStorage.setItem(response.data.documentType, response.data.hash);
              this.fileHash = response.data.hash

              this.$emit('update:value', this.inputValue);
              this.triggerChangeField()
            })
            .catch((error) => {
              this.lastRequest = error;
              this.$swal({
                title: "Erro!",
                icon: "error",
                text: "Os dados informados são inválidos. Revise os arquivos enviados.",
                confirmButtonText: "Ok",
              });
            });

        };
        reader.readAsDataURL(this.file);

        this.check = true
      }
      else {
        this.inputValue = null
        this.check = false
      }
    },

    openExplorer() {
      document.querySelector(`#file-${this.fileName.replace(' ', '-')}`).click();
    },

    triggerChangeField() {
      const parsedValues = {
        inputValue: this.inputValue, fileHash: this.fileHash
      }
      this.$emit("changeField", this.fileName, parsedValues);
    },
  }
};
</script>

<style>
input {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
</style>
