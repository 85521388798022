<style>
.custom-switch {
    display: inline-block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.custom-switch i {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 26px;
    background-color: #e6e6e6;
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;
}

.custom-switch i::before {
    content: "";
    position: absolute;
    left: 0;
    width: 42px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
    transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
    transition: all 0.25s linear;
}

.custom-switch i::after {
    content: "";
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    transform: translate3d(2px, 2px, 0);
    transition: all 0.2s ease-in-out;
}

.custom-switch:active i::after {
    width: 28px;
    transform: translate3d(2px, 2px, 0);
}

.custom-switch:active input:checked+i::after {
    transform: translate3d(16px, 2px, 0);
}

.custom-switch input {
    display: none;
}

.custom-switch input:checked+i {
    background: #5854F1;
    background: linear-gradient(54deg, #5854F1, #3535C4);
}

.custom-switch input:checked+i::before {
    transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}

.custom-switch input:checked+i::after {
    transform: translate3d(22px, 2px, 0);
}
</style>

<template>
    <div class="grid" :class="aligned ? '' : 'h-4'">
        <label class="input-label mb-1">{{ label }}</label>
        <label class="custom-switch mt-2">
            <input type="checkbox" v-model="checked" @change="change">
            <i></i>
        </label>
    </div>
</template>

<script>
export default {
    name: 'Checkbox',
    props: ['label', 'active', 'name', 'aligned'],
    data() {
        return {
            checked: false
        }
    },
    mounted() {
        this.checked = this.active
    },
    watch: {
        active: function (value) {
            this.checked = value
        }
    },
    methods: {
        change() {
            this.$emit('check', this.checked)
        }
    }
}
</script>