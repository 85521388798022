/* eslint-disable no-case-declarations */
import {
  differenceInDays,
  format,
  isValid,
  parse,
  parseISO,
  addMinutes,
  startOfToday,
  subYears,
} from "date-fns";
import { enGB } from "date-fns/locale";
import { cep, cnae, cnpj, cpf, email, ie, phone } from "validacao-docs";
import api from "./services/api";

let utils = {
  applyDateFormat(data) {
    const date = parseISO(data);
    const adjustedDate = addMinutes(date, date.getTimezoneOffset());
    return format(adjustedDate, "dd/MM/yyyy");
  },

  isValidDate(date, years) {
    if (typeof date === "string" || date instanceof String) {
      if (years) {
        let today = startOfToday();
        today = subYears(today, years);
        let diff = differenceInDays(
          today,
          parse(date, "P", new Date(), { locale: enGB })
        );
        if (diff < 0) {
          return false;
        }
      } else {
        let today = startOfToday();
        let diff = differenceInDays(
          today,
          parse(date, "P", new Date(), { locale: enGB })
        );
        if (diff < 0) {
          return false;
        }
      }
      if (date.length != "**/**/****".length) {
        return false;
      }
    } else {
      if (years) {
        let today = startOfToday();
        today = subYears(today, years);
        let diff = differenceInDays(today, date);
        if (diff < 0) {
          return false;
        }
      } else {
        let today = startOfToday();
        let diff = differenceInDays(today, date);
        if (diff < 0) {
          return false;
        }
      }
      if (format(date, "dd/MM/yyyy").length != "**/**/****".length) {
        return false;
      }
    }

    if (isValid(date)) {
      return true;
    }
    const parsedDate = parse(date, "P", new Date(), { locale: enGB });
    const isValidDate = isValid(parsedDate);
    return isValidDate;
  },

  applyMask(name, value) {
    if (Array.isArray(name)) name = name[1];
    if (value) {
      value = value.replace(/[^a-z0-9]/gi, "");
      switch (name) {
        case "CNPJ":
          return (
            value.substr(0, 2) +
            "." +
            value.substr(2, 3) +
            "." +
            value.substr(5, 3) +
            "/" +
            value.substr(8, 4) +
            "-" +
            value.substr(12, 2)
          );

        case "CNAE":
          return (
            value.substr(0, 4) +
            "-" +
            value.substr(4, 1) +
            "/" +
            value.substr(5, 2)
          );

        case "InscricaoEstadual":
          return value.substr(0, 8) + "-" + value.substr(8, 2);

        case "CEP":
          return value.substr(0, 5) + "-" + value.substr(5, 3);

        case "CPF":
          return (
            value.substr(0, 3) +
            "." +
            value.substr(3, 3) +
            "." +
            value.substr(6, 3) +
            "-" +
            value.substr(9, 2)
          );

        case "Celular":
          value = "+" + value;
          if (value.substr(0, 3) == "+55") {
            return (
              "+55(" +
              value.substr(3, 2) +
              ") " +
              value.substr(5, 5) +
              "-" +
              value.substr(10, 4)
            );
          } else {
            value = value.replaceAll("+", "");
            return (
              "(" +
              value.substr(0, 2) +
              ") " +
              value.substr(2, 5) +
              "-" +
              value.substr(7, 4)
            );
          }

        case "Telefone":
          value = "+" + value;
          if (value.substr(0, 3) === "+55") {
            if (value.length > "+############".length) {
              return (
                "+55(" +
                value.substr(3, 2) +
                ") " +
                value.substr(5, 5) +
                "-" +
                value.substr(10, 4)
              );
            } else {
              return (
                "+55(" +
                value.substr(3, 2) +
                ") " +
                value.substr(5, 4) +
                "-" +
                value.substr(9, 4)
              );
            }
          } else {
            value = value.replaceAll("+", "");
            if (value.length > "##########".length) {
              return (
                "(" +
                value.substr(0, 2) +
                ") " +
                value.substr(2, 5) +
                "-" +
                value.substr(7, 4)
              );
            } else {
              return (
                "(" +
                value.substr(0, 2) +
                ") " +
                value.substr(2, 4) +
                "-" +
                value.substr(6, 4)
              );
            }
          }
        default:
          return value;
      }
    }
  },

  async validate(
    index,
    form,
    validations,
    clientType,
    ignoreCNPJandCleanValidations = false
  ) {
    let availability = true;
    if (form[index]) {
      switch (index) {
        case "CNPJ":
          if (
            !cnpj.isValid(
              form[index]
                .replaceAll("/", "")
                .replaceAll(".", "")
                .replaceAll("-", "")
            )
          ) {
            validations[index] = "O CNPJ não é válido.";
            return false;
          }
          if (ignoreCNPJandCleanValidations) {
            availability = await api
              .get(
                `/` +
                  clientType +
                  `/disponibilidade/` +
                  form[index]
                    .replaceAll("/", "")
                    .replaceAll(".", "")
                    .replaceAll("-", "")
              )
              .catch(() => {});

            if (!availability) {
              validations[index] = "O CNPJ já está sendo usado.";
              return false;
            }
          }
          break;

        case "InscricaoEstadual":
          if (form.UF) {
            if (
              !ie.isValid(
                form[index]
                  .replaceAll("/", "")
                  .replaceAll(".", "")
                  .replaceAll("-", ""),
                form.UF
              ) &&
              !form.Isento
            ) {
              validations[index] = "A Inscrição Estadual não é válida.";
              return false;
            }
          }
          break;

        case "Telefone":
          if (!phone.isValidMask(form[index])) {
            validations[index] = "O formato do telefone não é válido.";
            return false;
          }
          break;

        case "Email":
          if (!email.isValidMask(form[index])) {
            validations[index] = "O formato do e-mail não é válido.";
            return false;
          }
          if (form[index].length > 150) {
            validations[index] = "O e-mail não pode ter mais de 150 caracteres.";
            return false;
          }
          break;

        case "CEP":
          if (!cep.isValidMask(form[index])) {
            validations[index] = "O formato do CEP não é válido.";
            return false;
          }
          if (!cep.isValid(form[index].replaceAll("-", ""))) {
            validations[index] = "O CEP não é válido.";
            return false;
          }
          break;

        case "CNAE":
          var valid = await cnae.isValid(
            form[index].replaceAll("/", "").replaceAll("-", "")
          );
          if (
            !valid ||
            form[index].replaceAll("/", "").replaceAll("-", "").length < 7
          ) {
            validations[index] = "O CNAE não é válido.";
            return false;
          }
          break;

        case "DataAbertura":
          if (!this.isValidDate(form[index])) {
            validations[index] = "A data não é válida.";
            return false;
          }
          break;

        case "DataNascimento":
          if (!this.isValidDate(form[index], 16)) {
            validations[index] = "A data não é válida.";
            return false;
          }
          break;

        case "CPF":
          if (!cpf.isValidMask(form[index])) {
            validations[index] = "O formato do CPF não é válido.";
            return false;
          }
          if (
            !cpf.isValid(form[index].replaceAll(".", "").replaceAll("-", ""))
          ) {
            validations[index] = "O CPF não é válido.";
            return false;
          }
          break;

        case "Celular":
          if (!phone.isValidMask(form[index])) {
            validations[index] = "O formato do celular não é válido.";
            return false;
          }
          break;

        case "FaturamentoMensal":
          const faturamentoMensal = form[index].replaceAll(
            ".",
            ""
          )
            .replaceAll(",", "")
            .replaceAll("R$ ", "");
          if (faturamentoMensal.length > 14) {
            validations[index] = "O faturamento mensal deve ter no máximo 12 dígitos a esquerda e 2 a direita.";
            return false;
          }
          break;

        case "RazaoSocial":
          if (form[index].length > 150) {
            validations[index] = "A razão social não pode ter mais de 150 caracteres.";
            return false;
          }
          break;
        
        case "NomeFantasia":
          if (form[index].length > 150) {
            validations[index] = "O nome fantasia não pode ter mais de 150 caracteres.";
            return false;
          }
          break;
        
        case "NomeCompleto":
          if (form[index].length > 100) {
            validations[index] = "O nome completo não pode ter mais de 100 caracteres.";
            return false;
          }
          break;

        case "NomeSocial":
          if (form[index].length > 100) {
            validations[index] = "O nome social não pode ter mais de 100 caracteres.";
            return false;
          }
          break;

        case "NomeMae":
          if (form[index].length > 100) {
            validations[index] = "O nome da mãe não pode ter mais de 100 caracteres.";
            return false;
          }
          break;

        case "IBGE":
          if (form[index].length > 10) {
            validations[index] = "O código IBGE não pode ter mais de 10 caracteres.";
            return false;
          }
          break;

        case "Numero":
          if (form[index].length > 5) {
            validations[index] = "O número não pode ter mais de 5 caracteres.";
            return false;
          }
          break;

        case "Complemento":
          if (form[index].length > 50) {
            validations[index] = "O complemento não pode ter mais de 50 caracteres.";
            return false;
          }
          break;

        case "Endereco":
          if (form[index].length > 100) {
            validations[index] = "O endereço não pode ter mais de 100 caracteres.";
            return false;
          }
          break;

        case "Bairro":
          if (form[index].length > 50) {
            validations[index] = "O bairro não pode ter mais de 50 caracteres.";
            return false;
          }
          break;

        case "Cidade":
          if (form[index].length > 100) {
            validations[index] = "A cidade não pode ter mais de 100 caracteres.";
            return false;
          }
          break;

        case "Pais":
          if (form[index].length > 50) {
            validations[index] = "O país não pode ter mais de 50 caracteres.";
            return false;
          }
          break;

        case "RG":
          if (form[index].length > 20) {
            validations[index] = "O RG não pode ter mais de 20 caracteres.";
            return false;
          }
          break;
      }
      delete validations[index];
      return true;
    }
  },

  deepClone(obj) {
    if (obj === null || typeof obj !== "object") {
      return obj;
    }

    if (obj instanceof Date) return new Date(obj);
    if (obj instanceof RegExp) return new RegExp(obj);
    if (obj instanceof Boolean) return new Boolean(obj.valueOf());
    if (obj instanceof Number) return new Number(obj.valueOf());
    if (obj instanceof String) return new String(obj.valueOf());

    if (ArrayBuffer.isView(obj))
      return new obj.constructor(obj.buffer.slice(0));
    if (obj instanceof ArrayBuffer) return obj.slice(0);

    if (obj instanceof Map) {
      return new Map(
        [...obj.entries()].map(([k, v]) => [
          this.deepClone(k),
          this.deepClone(v),
        ])
      );
    }

    if (obj instanceof Set) {
      return new Set([...obj].map(this.deepClone));
    }

    if (Array.isArray(obj)) {
      const copyArray = [];
      for (let i = 0; i < obj.length; i++) {
        copyArray[i] = this.deepClone(obj[i]);
      }
      return copyArray;
    }

    const copyObj = {};
    for (const key in obj) {
      if (Object.hasOwn(obj, key)) {
        copyObj[key] = this.deepClone(obj[key]);
      }
    }

    return copyObj;
  },

  formatFormToDatabase(data) {
    let form = this.deepClone(data);

    if (form.empresa.socialContract) {
      form.empresa.SocialContract = {
        fileName: form.empresa.socialContract.inputValue
          ? form.empresa.socialContract.inputValue
          : form.empresa.socialContract.fileHash,
        fileHash: form.empresa.socialContract.fileHash,
      };
    }

    if (form.empresa.procuration) {
      form.empresa.Procuration = {
        fileName: form.empresa.procuration.inputValue
          ? form.empresa.procuration.inputValue
          : form.empresa.procuration.fileHash,
        fileHash: form.empresa.procuration.fileHash,
      };
    }

    form.empresa.CNPJ = form.empresa.CNPJ.replaceAll("-", "")
      .replaceAll("/", "")
      .replaceAll(".", "");
    form.empresa.CNAE = form.empresa.CNAE.toString()
      .replaceAll("-", "")
      .replaceAll(".", "")
      .replaceAll("/", "");
    form.empresa.Telefone = form.empresa.Telefone.replaceAll(" ", "");
    form.empresa.CEP = form.empresa.CEP.replaceAll("-", "");
    form.empresa.FaturamentoMensal = form.empresa.FaturamentoMensal.replaceAll(
      ".",
      ""
    )
      .replaceAll(",", ".")
      .replaceAll("R$ ", "");

    return form.empresa;
  },

  formatDataToForm(data) {
    const openingDate = parseISO(data.openingDate);
    const adjustedOpeningDate = addMinutes(
      openingDate,
      openingDate.getTimezoneOffset()
    );
    const empresa = {
      RazaoSocial: data.corporateName,
      NomeFantasia: data.fantasyName,
      Email: data.email,
      Telefone: this.applyMask("Telefone", data.phone),
      DataAbertura: adjustedOpeningDate,
      CNAE: this.applyMask("CNAE", data.cnae),
      FaturamentoMensal: Intl.NumberFormat("pt-br", {
        style: "currency",
        currency: "BRL",
      }).format(data.monthlyBilling),
      ConstituicaoEmpresa: data.companyConstituition,
      CNPJ: this.applyMask("CNPJ", data.cnpj),
      CEP: this.applyMask("CEP", data.zipCode),
      IBGE: data.ibgeCode,
      Numero: data.number,
      Bairro: data.district,
      Complemento: data.complement,
      Endereco: data.address,
      Pais: data.country,
      Isento: data.isTaxExempt,
      InscricaoEstadual: this.applyMask("IE", data.stateRegistration),
      UF: data.state,
      Cidade: data.city,
      RegimeTributario: data.taxRegime,
      TipoAtuacao: data.operationType,
    };

    if (data.uuid) {
      empresa.uuid = data.uuid;
    }
    if (data.documentProcuration) {
      empresa.procuration = {
        fileName: data.documentProcuration,
        fileHash: data.documentProcuration,
      };
      empresa.procurationInputValue = data.documentProcuration;
    }
    if (data.documentSocialContract) {
      empresa.socialContract = {
        fileName: data.documentSocialContract,
        fileHash: data.documentSocialContract,
      };
      empresa.socialContractInputValue = data.documentSocialContract;
    }
    return empresa;
  },

  formatRepresentativeToForm(data) {
    return data.map((item) => {
      const birthdayDate = parseISO(item.birthday);
      const adjustedBirthdayDate = addMinutes(
        birthdayDate,
        birthdayDate.getTimezoneOffset()
      );
      const representative = {
        NomeCompleto: item.fullName,
        NomeMae: item.motherName,
        NomeSocial: item.socialName,
        CPF: this.applyMask("CPF", item.cpf),
        DataNascimento: adjustedBirthdayDate,
        RG: item.rg,
        UFRG: item.stateRg,
        Email: item.email,
        Bairro: item.district,
        UF: item.state,
        CEP: this.applyMask("CEP", item.zipCode),
        Cidade: item.city,
        Complemento: item.complement,
        Endereco: item.address,
        Numero: item.number,
        Pais: item.country,
        Telefone: this.applyMask("Telefone", item.phone),
        Celular: this.applyMask("Celular", item.cellPhone),
        isMain: item.isMain,
        rgFront: undefined,
        rgFrontInputValue: undefined,
        rgBack: undefined,
        rgBackInputValue: undefined,
        cnh: undefined,
        cnhInputValue: undefined,
        selfie: undefined,
        selfieInputValue: undefined,
      };
      if (item.uuid) {
        representative.uuid = item.uuid;
      }
      if (item.documentRgFront) {
        representative.rgFront = item.documentRgFront;
        representative.rgFrontInputValue = item.documentRgFront;
      }
      if (item.documentRgBack) {
        representative.rgBack = item.documentRgBack;
        representative.rgBackInputValue = item.documentRgBack;
      }
      if (item.documentCnh) {
        representative.cnh = item.documentCnh;
        representative.cnhInputValue = item.documentCnh;
      }
      if (item.documentSelfie) {
        representative.selfie = item.documentSelfie;
        representative.selfieInputValue = item.documentSelfie;
      }
      return representative;
    });
  },

  formatRepresentativeToDatabase(data) {
    const formatedData = this.deepClone(data);
    formatedData.CPF = data.CPF.replaceAll("-", "").replaceAll(".", "");
    formatedData.RG = data.RG.replaceAll("-", "");
    if (data.selfie) {
      formatedData.Selfie = {
        fileHash: data.selfie,
        fileName: data.selfieInputValue,
      };
    }
    if (data.cnh) {
      formatedData.Cnh = {
        fileHash: data.cnh,
        fileName: data.cnhInputValue,
      };
    }
    if (data.rgBack) {
      formatedData.RgBack = {
        fileHash: data.rgBack,
        fileName: data.rgBackInputValue,
      };
    }
    if (data.rgFront) {
      formatedData.RgFront = {
        fileHash: data.rgFront,
        fileName: data.rgFrontInputValue,
      };
    }
    formatedData.Telefone = data.Telefone.replaceAll("-", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll(" ", "");
    formatedData.Celular = data.Celular.replaceAll("-", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll(" ", "");
    formatedData.CEP = data.CEP.replaceAll("-", "");
    return formatedData;
  },

  formatResponsibleToForm(data) {
    const responsavel = {};
    const tecnico = {};
    data.forEach((item) => {
      if (item.role === "tecnico") {
        tecnico.tecnico = {
          uuid: item.uuid,
          CPF: this.applyMask("CPF", item.cpf),
          Celular: this.applyMask("Celular", item.cellPhone),
          NomeCompleto: item.fullName,
          Email: item.email,
        };
      }
      if (item.role === "responsavel") {
        tecnico.responsavel = {
          uuid: item.uuid,
          CPF: this.applyMask("CPF", item.cpf),
          Celular: this.applyMask("Celular", item.cellPhone),
          NomeCompleto: item.fullName,
          Email: item.email,
        };
      }
      if (item.role === "financeiro") {
        responsavel.uuid = item.uuid;
        responsavel.CPF = this.applyMask("CPF", item.cpf);
        responsavel.Celular = this.applyMask("Celular", item.cellPhone);
        responsavel.NomeCompleto = item.fullName;
        responsavel.Email = item.email;
      }
    });
    return { tecnico, responsavel };
  },

  formatResponsibleToDatabase(data) {
    const formatedData = this.deepClone(data);
    formatedData.CPF = data.CPF.replaceAll("-", "").replaceAll(".", "");
    formatedData.Celular = data.Celular.replaceAll("-", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll(" ", "");
    return formatedData;
  },

  getUFs() {
    const UFs = [
      "AC",
      "AL",
      "AP",
      "AM",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MT",
      "MS",
      "MG",
      "PA",
      "PB",
      "PR",
      "PE",
      "PI",
      "RJ",
      "RN",
      "RS",
      "RO",
      "RR",
      "SC",
      "SP",
      "SE",
      "TO",
    ];
    return UFs;
  },

  getCurrentPosition() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        reject
      );
    });
  },

  getPermissionByName(name) {
    if (!navigator.permissions) {
      return null;
    }
    return new Promise((resolve, reject) => {
      navigator.permissions
        .query({ name })
        .then((result) => resolve(result.state))
        .catch(reject);
    });
  },

  async getCitiesByUF(ufAbbreviation) {
    let cities = await api.get(`locations/states/${ufAbbreviation}/cities`);
    cities = this.sort(cities.data, "name");
    return cities.map((city) => (city = city.name));
  },

  sort(array, index) {
    return array.sort(function(first, second) {
      if (first[index] > second[index]) {
        return 1;
      }
      if (first[index] < second[index]) {
        return -1;
      }
      return 0;
    });
  },
};

export default utils;
