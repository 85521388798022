<style>
.card {
    transition: o.25s
}
</style>

<template>
    <div class="w-100 bg-white shadow-xl rounded-lg py-6 px-6 text-left border">
        <h1 :class="titlePosition == 'center' ? 'text-center' : ''"
            class="text-lg inline-block w-full md:text-3xl font-bold text-gray-900 tracking-tight">{{ title }}</h1>
        <slot>
        </slot>
    </div>
</template>

<script>
export default {
    name: 'Card',
    props: ['title', 'titlePosition']
}
</script>