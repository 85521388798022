import axios from "axios";

const getUrl = () => {
  if (document.domain === "app.tecnopay.me") {
    return "https://api.tecnopay.me/v1";
  }

  if (document.domain === "app-sandbox.tecnopay.me") {
    return "https://api-sandbox.tecnopay.me/v1";
  }

  return "http://localhost:3003/v1";
};

const api = axios.create({
  baseURL: getUrl(),
});

export default api;
