import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import App from "./App.vue";
import "./index.css";
import Maska from "maska";
import VueSweetalert2 from "vue-sweetalert2";
import copyText from "@meforma/vue-copy-to-clipboard";
import "sweetalert2/dist/sweetalert2.min.css";

import SoftwareHouse from "./components/pages/SoftwareHouse";
import EstabelecimentoComercial from "./components/pages/EstabelecimentoComercial";
import Home from "./components/pages/Home";
import ConsentimentoDados from "./components/pages/ConsentimentoDados";
import store from "./store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/softwarehouse/:hash",
    name: "SoftwareHouses",
    component: SoftwareHouse,
  },
  {
    path: "/estabelecimento/:hash",
    name: "Estabelecimentos",
    component: EstabelecimentoComercial,
  },
  {
    path: "/autorizacao/:hash",
    name: "ConsentimentoDados",
    component: ConsentimentoDados,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

createApp(App)
  .use(VueSweetalert2)
  .use(Maska)
  .use(store)
  .use(router)
  .use(copyText)
  .mount("#app");
