import { createStore } from "vuex";

export default createStore({
  state: {
    materaAccountId: "",
    materaAccountStatus: "",
  },
  mutations: {
    updateMateraAccountId(state, value) {
      state.materaAccountId = value;
    },
    updateMateraAccountStatus(state, value) {
      state.materaAccountStatus = value;
    },
  },
});
