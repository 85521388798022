<style>
.breadcrumb {
    display: flex;
    color: white !important;
    background: #5854F1;
    background: linear-gradient(54deg, #4f46e5, #4f46e5);
    border-radius: 10px;
    -webkit-box-shadow: 5px 5px 23px -8px #000000;
    box-shadow: 5px 5px 23px -8px #000000;
}

li.item {
    width: 100%;
    text-align: center;
    display: inline-block;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    left: 6px;
    z-index: 1;
    font-size: 12px;
}

li.item:last-child {
    z-index: 0;
    left: -5px;
}

li.item:last-child::before {
    border: 0.01px transparent;
    margin-left: 6px;
    border-radius: 0 8px 0 0;
    transform: skew(0deg);
}

li.item:last-child::after {
    border: 0.1px transparent;
    margin-left: 6px;
    border-radius: 0 0 8px 0;
    transform: skew(0deg);
}

li.item::before,
li.item::after {
    content: "";
    position: absolute;
    left: -1px;
    height: 50%;
    width: 100%;
    background: #818cf8;
    z-index: -2;
}

li.item.enabled::before,
li.item.enabled::after {
    background: transparent;
}

li.item::before {
    top: 0px;
    padding-bottom: 0.125px;
    transform: skew(30deg);
    border-left: 6px solid white;
    border-right: 6px solid transparent;
}

li.item.beforelast.enabled {
    background: #4f46e5;
    background: linear-gradient(54deg, #4f46e5, #4f46e5);
    border-radius: 50px;
}

li.item.current::before,
li.item.current::after {
    background: transparent;
}

li.item.current::after,
li.item.current::before {
    background: #5854F1;
    background: linear-gradient(54deg, #312e81, #312e81);
}

li.item.beforelast::before,
li.item.beforelast::after {
    border-right: 6px solid white;
    border-right: 6px solid white;
}

li.item::after {
    bottom: 0px;
    padding-top: 0.125px;
    transform: skew(-30deg);
    border-left: 6px solid white;
    border-right: 6px solid transparent;
}

li.item:first-child::before {
    border: 1px transparent;
    border-radius: 8px 0 0 0;
    width: 120%;
    margin-left: -6px;
    transform: skew(0deg);
}

li.item:first-child::after {
    border: 1px transparent;
    border-radius: 0 0 0 8px;
    margin-left: -6px;
    width: 120%;
    transform: skew(0deg);
}

@media only screen and (max-width: 987px) {
    .step-icon {
        display: block;
        padding-left: 6px;
    }

    .step-text {
        display: none;
    }
}

@media only screen and (min-width: 987px) {
    div.step-icon {
        display: none;
    }

    div.step-text {
        display: block;
    }
}
</style>

<template>
    <div class="breadcrumb mb-5">
        <li class="item"
            :class="(item == items[items.length - 2] ? 'beforelast ' : '') + (item.index < index ? 'enabled' : 'disabled') + (item.index === index - 1 ? ' current' : '')"
            v-for="item in items" :key="item.index">
            <div class="step-text font-bold text-sm">{{ item.label }}</div>
            <div class="step-icon">
                <FontAwesomeIcon :icon="item.icon" />
            </div>
        </li>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
    name: 'Breadcumb',
    props: ['items', 'index'],
    components: {
        FontAwesomeIcon
    },
}
</script>
