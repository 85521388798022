<style>
.custom-input {
    outline: none;
    border-color: #e6e6e6;
    moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.custom-input:focus {
    border: solid 1px #5854F1;
}
</style>

<template>
    <div class="grid">
        <label class="input-label mb-1">
            {{ label }} <i class="text-xs thin">{{ optional ? ' (Opcional)' : '' }}</i>
            <FontAwesomeIcon class="ml-4 text-black self-center animate-spin text-primary" v-if="loading"
                :icon="spinner" />
        </label>
        <div v-if="type == 'phone'" class="grid grid-cols-12 gap-3 flex justify-between">
            <Multiselect v-model="countryCode" :class="validation ? 'multiselect-input-invalid' : ''"
                class="col-span-4 mr-2 w-full" :options="['+55', '+1']" :disabled="disabled" />
            <input :class="validation ? 'border-red-500 border-2 ' + name : name" @change="change"
                class="custom-input col-span-8 rounded-lg py-2 px-5" v-model="inputValue" v-maska="mask"
                :disabled="disabled" />
        </div>
        <input v-else-if="type == 'currency'" :class="validation ? 'border-red-500 border-2 ' + name : name"
            class="custom-input rounded-lg py-2 px-5 w-full" @change="change" v-model.lazy="inputValue" v-money="money"
            :disabled="disabled" :type="hidden ? 'password' : 'text'" :autofocus="autofocus ? 'autofocus' : ''" />
        <input v-else :class="validation ? 'border-red-500 border-2 ' + name : name"
            class="custom-input rounded-lg py-2 px-5 w-full" @keydown="format" @change="change" v-model="inputValue"
            v-maska="mask" :disabled="disabled" :type="hidden ? 'password' : 'text'"
            :autofocus="autofocus ? 'autofocus' : ''" />
        <span :class="'text-red-500 text-xs ' + name.toString().replaceAll(',', ' ')">{{ validation }}&nbsp;</span>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import utils from '@/utils'
import { VMoney } from 'v-money'

export default {
    name: 'Input',
    components: { Multiselect, FontAwesomeIcon },
    directives: { money: VMoney },
    props: ['label', 'value', 'name', 'text', 'mask', 'validation', 'type', 'disabled', 'clean', 'autofocus', 'loading', 'optional', 'hidden'],
    watch: {
        clean: function (clean) {
            if (clean) {
                this.inputValue = null
            }
        },
        value: function (value) {
            if (value) {
                value = String(value)
                this.inputValue = value

                if (this.type == 'phone') {
                    this.inputValue = value.substr(3, value.length)
                }
                if (value !== undefined && this.mask !== undefined && value.length > 5 && this.type != 'phone') {
                    this.inputValue = utils.applyMask(this.name, this.inputValue)
                }
            } else {
                this.inputValue = null
            }
        }
    },
    data() {
        return {
            countryCode: '+55',
            inputValue: null,
            spinner: faSpinner,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2,
            }
        }
    },
    methods: {
        format() {
            if (this.type == 'currency') {
                this.inputValue = this.inputValue ? 'R$ ' + this.inputValue.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1.$2').replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",") : this.inputValue
            }
        },

        change() {
            this.$emit('change', this.name, this.type == 'phone' ? this.countryCode + ' ' + this.inputValue : this.inputValue)
        },
    }
}
</script>