<template>
    <button class="text-center bg-gray-200 outline-none rounded-lg py-2 px-5 mt-auto cursor-pointer flex focus:bg-gray-300 focus:outline-none justify-center" :class="iconPosition == 'left' ? ' flex-row-reverse' : ''">
        <div>{{label}}</div>
    </button>
</template>

<script>
    import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

    export default {
        name: 'Button',
        props: ['label', 'icon', 'loading', 'iconPosition'],
        components: {
        },
        data () {
            return {
                spinner: faCircleNotch
            }
        },
    }
</script>