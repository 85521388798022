<style>
.register-link {
  transition: 0.45s;
}

.register-link:hover {
  font-size: 2.25rem;
}
</style>

<template>
  <div class="flex bg-contain bg-primary h-screen text-white">
    <div class="p-4 m-auto grid grid-cols-11 gap-12">
      <div class="col-span-11 text-center">
        <h1 class="text-4xl mb-8"><b>Baixe agora o aplicativo do TecnoPay</b></h1>
      </div>
      <embed src="/icon.svg" class="col-span-11 xs:w-min sm:w-min md:w-min w-max lg:col-span-5 z-10 m-auto" />
      <div class="flex lg:flex hidden z-10 lg:col-span-1">
        <div class="m-auto border-l z-10 h-full border-white w-1"></div>
      </div>
      <div class="col-span-11 lg:col-span-5 grid z-10 relative my-auto gap-8">
        <div class="text-center mx-auto">
          <div class="mx-auto text-2xl"><b>A conta PIX dentro do seu ERP</b></div>
          <div class="mx-auto text-xl">O diferencial que seu software precisa</div>
        </div>
        <div class="flex m-auto gap-5">
          <a href="https://play.google.com/store/apps/details?id=com.tecnospeed.tecnopay" class="cursor-pointer"><embed
              width="150" src="/google-play.svg" /></a>
          <a class="cursor-pointer z-10"><embed width="135" src="/apple-store.svg" /></a>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    register() {
      this.$router.push('softwarehouse')
    }
  }
}
</script>