<script>
import api from "@/services/api";

export default {
  name: "ConsentimentoDados",
  mounted() {
    api
      .put("/hashes/" + this.$route.params.hash)
      .then(() => {
        this.$swal({
          title: "Sucesso!",
          icon: "success",
          text: "Seus dados foram autorizados para uso no cadastro Tecnopay.",
          confirmButtonText: "Fechar",
        }).then(() => {
          window.close();
        });
      })
      .catch((response) => {
        this.$emit("registerLastRequest", response);
      });
  },
};
</script>
