<template>
  <div v-if="success" class="toast rounded-lg p-3 w-fill sm:w-1/3 flex justify-between bg-success" :class="hidden ? 'disappear' : ''">
    {{ label }}
    <div @click="$emit('hide')"><FontAwesomeIcon :icon="close" class="my-auto cursor-pointer" /></div>
  </div>
  <div v-else class="toast rounded-lg p-3 w-1/2 sm:w-1/3 flex justify-between bg-error" :class="hidden ? 'disappear' : ''">
    {{ label }}
    <div @click="$emit('hide')"><FontAwesomeIcon :icon="close" class="my-auto cursor-pointer" /></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'Toast',
  components: {
    FontAwesomeIcon
  },
  props: {
    label: String,
    hidden: Boolean,
    success: Boolean
  },
  data () {
    return {
      close: faTimes
    }
  }
})
</script>

<style>
  .toast {
    position: absolute;
    z-index: 30;
    color: white;
    top: 0%;
    right: 0%;
    margin: 25px;
    transition: 0.4s;
  }

  .bg-error {
    background: #f15454;
    background: linear-gradient(54deg, #f15454 0%, rgb(219, 35, 35) 70%, hwb(0 8% 23%) 100%);
  }

  .bg-success {
    background: #42b983;
    background: linear-gradient(90deg, rgb(66,185,131) 0%, rgb(24,158,24) 70%, rgb(8,105,8) 100%);
  }

  .disappear {
    transform: translateX(150%);
  }
</style>
