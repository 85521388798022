<template>
    <div class="tabs custom-textarea">
        <div class="tab">
            <input type="checkbox" id="company" checked>
            <label class="tab-label" for="company" checked>Dados da Empresa</label>
            <div class="tab-content">
                <div class="grid grid cols-12 gap-5">
                    <div class="flex section-title col-span-12">
                        Dados Cadastrais
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            CNPJ
                        </div>
                        <div class="data">
                            {{ data.empresa.CNPJ }}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Razão Social
                        </div>
                        <div class="data">
                            {{ data.empresa.RazaoSocial }}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Nome Fantasia
                        </div>
                        <div class="data">
                            {{ data.empresa.NomeFantasia }}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Inscrição Estadual
                        </div>
                        <div class="data">
                            {{ data.empresa.InscricaoEstadual }}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            CNAE
                        </div>
                        <div class="data">
                            {{ data.empresa.CNAE }}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6" v-if="clientType === 'software-house'">
                        <div class="label">
                            Formato de Constituição
                        </div>
                        <div class="data">
                            {{ formats.filter((format) => format.value == data.empresa.ConstituicaoEmpresa)[0].label }}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Regime Tributário
                        </div>
                        <div class="data">
                            {{ regimes.filter((regime) => regime.value == data.empresa.RegimeTributario)[0].label }}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Faturamento Mensal
                        </div>
                        <div class="data">
                            {{ 
                                data.empresa.FaturamentoMensal
                            }}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Data de Abertura
                        </div>
                        <div class="data">
                            {{ applyDateFormat(data.empresa.DataAbertura) }}
                        </div>
                    </div>
                    <div class="w-full col-span-12 border-t border border-grey-300" />
                    <div class="section-title col-span-12">Dados Para Contato</div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            E-mail
                        </div>
                        <div class="data">
                            {{ data.empresa.Email }}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Telefone
                        </div>
                        <div class="data">
                            {{ data.empresa.Telefone.replaceAll('+55', '') }}
                        </div>
                    </div>
                    <div class="w-full col-span-12 border-t border border-grey-300" />
                    <div class="section-title col-span-12">
                        Dados do Endereço
                    </div>
                    <div class="col-span-12">
                        {{ data.empresa.Endereco }}, {{ data.empresa.Numero }}. {{ data.empresa.Complemento ?
                                '(' + data.empresa.Complemento + ')' : '' }} <br />
                        {{ data.empresa.Bairro }}, {{ data.empresa.Cidade }} - {{ data.empresa.UF }} <br />
                        {{ data.empresa.CEP }}
                    </div>
                </div>
            </div>
        </div>
        <div class="tab">
            <input type="checkbox" id="representatives" checked>
            <label class="tab-label" for="representatives" checked>Dados dos Representantes Legais</label>
            <div class="tab-content">
                <div class="grid grid cols-12 gap-5">
                    <div class="flex section-title col-span-12">
                        Dados Cadastrais
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            CPF
                        </div>
                        <div class="data">
                            {{ data.representantes[0].CPF }}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            RG
                        </div>
                        <div class="data">
                            {{ data.representantes[0].RG }}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Nome Completo
                        </div>
                        <div class="data">
                            {{ data.representantes[0].NomeCompleto }}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Nome da Mãe
                        </div>
                        <div class="data">
                            {{ data.representantes[0].NomeMae }}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Data de Nascimento
                        </div>
                        <div class="data">
                            {{ applyDateFormat(data.representantes[0].DataNascimento) }}
                        </div>
                    </div>
                    <div class="w-full col-span-12 border-t border border-grey-300" />
                    <div class="section-title col-span-12">Dados Para Contato</div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            E-mail
                        </div>
                        <div class="data">
                            {{ data.representantes[0].Email }}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Telefone
                        </div>
                        <div class="data">
                            {{ data.representantes[0].Telefone.replaceAll('+55', '') }}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Celular
                        </div>
                        <div class="data">
                            {{ data.representantes[0].Celular.replaceAll('+55', '') }}
                        </div>
                    </div>
                    <div class="w-full col-span-12 border-t border border-grey-300" />
                    <div class="section-title col-span-12">
                        Dados do Endereço
                    </div>
                    <div class="col-span-12">
                        {{ data.representantes[0].Endereco }}, {{ data.representantes[0].Numero }}. {{
                                data.representantes[0].Complemento ? '(' + data.representantes[0].Complemento + ')' : '' }} <br />
                        {{ data.representantes[0].Bairro }}, {{ data.representantes[0].Cidade }} - {{
                                data.representantes[0].UF }} <br />
                        {{ data.representantes[0].CEP }}
                    </div>
                </div>
            </div>
        </div>
        <div class="tab" v-if="data.tecnico?.responsavel && clientType === 'software-houses'">
            <input type="checkbox" id="responsavel" checked>
            <label class="tab-label" for="responsavel" checked>Dados do Responsável</label>
            <div class="tab-content">
                <div class="grid grid cols-12 gap-5">
                    <div class="flex section-title col-span-12">
                        Dados Cadastrais
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            CPF
                        </div>
                        <div class="data">
                            {{ data.tecnico.responsavel.CPF }}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Nome Completo
                        </div>
                        <div class="data">
                            {{ data.tecnico.responsavel.NomeCompleto }}
                        </div>
                    </div>
                    <div class="w-full col-span-12 border-t border border-grey-300" />
                    <div class="section-title col-span-12">Dados Para Contato</div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            E-mail
                        </div>
                        <div class="data">
                            {{ data.tecnico.responsavel.Email }}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Celular
                        </div>
                        <div class="data">
                            {{ data.tecnico.responsavel.Celular.replaceAll('+55', '') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab" v-if="data.tecnico?.tecnico && clientType === 'software-houses'">
            <input type="checkbox" id="tecnico" checked>
            <label class="tab-label" for="tecnico" checked>Dados do Técnico</label>
            <div class="tab-content">
                <div class="grid grid cols-12 gap-5">
                    <div class="flex section-title col-span-12">
                        Dados Cadastrais
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            CPF
                        </div>
                        <div class="data">
                            {{ data.tecnico.tecnico.CPF }}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Nome Completo
                        </div>
                        <div class="data">
                            {{ data.tecnico.tecnico.NomeCompleto }}
                        </div>
                    </div>
                    <div class="w-full col-span-12 border-t border border-grey-300" />
                    <div class="section-title col-span-12">Dados Para Contato</div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            E-mail
                        </div>
                        <div class="data">
                            {{ data.tecnico.tecnico.Email }}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Celular
                        </div>
                        <div class="data">
                            {{ data.tecnico.tecnico.Celular.replaceAll('+55', '') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab" v-if="data.responsavel && clientType !== 'software-houses'">
            <input type="checkbox" id="tecnico" checked>
            <label class="tab-label" for="tecnico" checked>Dados do Responsável Financeiro</label>
            <div class="tab-content">
                <div class="grid grid cols-12 gap-5">
                    <div class="flex section-title col-span-12">
                        Dados Cadastrais
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            CPF
                        </div>
                        <div class="data">
                            {{ data.responsavel.CPF }}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Nome Completo
                        </div>
                        <div class="data">
                            {{data.responsavel.NomeCompleto}}
                        </div>
                    </div>
                    <div class="w-full col-span-12 border-t border border-grey-300" />
                    <div class="section-title col-span-12">Dados Para Contato</div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            E-mail
                        </div>
                        <div class="data">
                            {{data.responsavel.Email}}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Celular
                        </div>
                        <div class="data">
                            {{ data.responsavel.Celular.replaceAll('+55', '') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '@/utils'
export default {
    name: 'ConfirmFormData',
    props: ['data', 'clientType'],
    data() {
        return {
            regimes: [
                { value: 'lucro_real', label: 'Lucro Real' },
                { value: 'lucro_presumido', label: 'Lucro Presumido' },
                { value: 'simples_nacional', label: 'Simples Nacional' },
                { value: 'mei', label: 'MEI' }
            ],
            formats: [
                { value: 'sociedade_anonima', label: 'Sociedade Anônima' },
                { value: 'sociedade_simples', label: 'Sociedade Simples' },
                { value: 'sociedade_empresaria_limitada', label: 'Sociedade Empresária Limitada' },
                { value: 'microempreendedor_individual', label: 'Microempreendedor Individual' },
                { value: 'empresa_individual_de_responsabilidade_limitada', label: 'Empresa Individual de Responsabilidade Limitada' },
                { value: 'empresa_individual', label: 'Empresa Individual' },
                { value: 'sociedade_limitada_unipessoal', label: 'Sociedade Limitada Unipessoal' },
            ],
        }
    },
    methods: {
        applyMask(index, value) {
            return utils.applyMask(index, value)
        },
        applyDateFormat(value) {
            if (value instanceof Date) {
                return utils.applyDateFormat(value.toISOString())
            }
            return utils.applyDateFormat(value)
        }
    }
}
</script>