<template>
  <div v-if="loadingData" :class="loadingData ? 'absolute flex h-screen w-screen bg-gray-50' : 'invisible'">
    <div class="m-auto z-10 w-screen">
      <embed width="150" height="150" src="/colorful-loading.gif" class="mx-auto mb-6" />
    </div>
  </div>
  <div v-else :class="registering ? '' : 'w-full h-full absolute'">
    <AuthEstabelecimentoComercial 
      clientType="company" 
      class="absolute h-full" 
      v-if="!registering"
      @resume="resume" 
      @new="newRegister"      
    />
    <CreateEstabelecimentoComercial 
      :CNPJ="CNPJ" 
      :mediatorFee="mediatorFee" 
      :estabelecimentoCnpj="estabelecimentoCnpj"
      :cnpjLocked="cnpjLocked" 
      :estabelecimentoRazaoSocial="estabelecimentoRazaoSocial"
      clientType="company" 
      v-if="registering" 
      :data="formData" 
      :protocolValue="Protocol" 
      :isSameThatTheSoftwareHouse="isSameThatTheSoftwareHouse" 
      :preRegister="preRegister"
    />
  </div>

</template>

<script>
import CreateEstabelecimentoComercial from "../business/Create";
import AuthEstabelecimentoComercial from "../business/Auth";
import api from "@/services/api";

export default {
  name: "EstabelecimentoComercial",
  components: {
    CreateEstabelecimentoComercial,
    AuthEstabelecimentoComercial,
  },
  data() {
    return {
      CNPJ: null,
      estabelecimentoCnpj: null,
      cnpjLocked: false,
      mediatorFee: null,
      registering: false,
      formData: {},
      isSameThatTheSoftwareHouse: false,
      Protocol: null,
      estabelecimentoRazaoSocial: "",
      loadingData: true,
      preRegister: null,
    };
  },
  async mounted() {
    await this.getData(this.$route.params.hash)
    this.loadingData = false
  },
  methods: {
    async getData(uuid) {
      try {
        const preRegister = await api.get(`/pre-register/company/${uuid}`)
        this.preRegister = preRegister.data
        this.CNPJ = this.preRegister.softwareHouse.cnpj
        this.estabelecimentoCnpj = this.preRegister.cnpj;
  
        const softwareHouseAvailability = await api.get("/software-houses/disponibilidade/" + this.CNPJ)
        if (softwareHouseAvailability.data.disponivel) {
          this.$swal({
            title: "Erro!",
            text: "O CNPJ da Software House vinculado a esse cadastro não consta em nossos dados.\n" +
              "Por favor, entre em contato com a sua Software House para maiores esclarecimentos",
            confirmButtonText: "Ok",
          }).then(() => {
            this.$router.push("/");
          });
        } else {
          const commercialEstablishmentAvailability = await api.get(`/shop/commercial-establishments/availability/${this.estabelecimentoCnpj}`)
          if (!commercialEstablishmentAvailability.data.disponivel) {
            this.$swal({
              title: "Erro!",
              text:
                "O CNPJ do Estabelecimento Comercial já está cadastrado em nossa base de dados.\n" +
                "Por favor, entre em contato com a sua Software House para maiores esclarecimentos.",
              confirmButtonText: "Ok",
            }).then(() => {
              this.$router.push("/");
            });
          } else {
            this.cnpjLocked = true;
            this.estabelecimentoRazaoSocial = this.preRegister.corporateName;
            if (this.CNPJ === this.estabelecimentoCnpj) {
              this.isSameThatTheSoftwareHouse = true
              const softwareHouse = await api.get(`software-houses/${this.estabelecimentoCnpj}/validate-protocol`)
              const softwareHouseData = softwareHouse.data
              delete softwareHouseData.id
              delete softwareHouseData.uuid
              delete softwareHouseData.contracts
  
              softwareHouseData.representatives = softwareHouseData.representatives.map((representative) => {
                delete representative.id
                delete representative.uuid
                return representative
              })
              softwareHouseData.responsibles = softwareHouseData.responsibles.map((responsible) => {
                delete responsible.id
                delete responsible.uuid
                return responsible
              })
  
              this.estabelecimentoSoftwareHouseData = softwareHouseData
              this.formData = softwareHouseData
            }
            
            if (!this.preRegister.isRegistered) {
              this.registering = true
            }
          }
        }
      } catch(error) {
        this.$emit("registerLastRequest", error);
      }
    },

    async newRegister() {
      this.formData = {};
      this.registering = true;
    },

    async resume(data) {
      const estabelecimentoData = await data.data
      if (!estabelecimentoData.representatives.length && this.estabelecimentoSoftwareHouseData?.representatives) {
        estabelecimentoData.representatives = this.estabelecimentoSoftwareHouseData.representatives
      }
      if (this.estabelecimentoSoftwareHouseData?.validations?.length) {
        estabelecimentoData.validations = [...estabelecimentoData.validations, ...this.estabelecimentoSoftwareHouseData.validations]
      }
      
      this.formData = estabelecimentoData;
      this.Protocol = await data.protocol;
      this.registering = true;
    },
  },
};
</script>
